import React from "react";
import { Link } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { FaGlobe } from "@react-icons/all-files/fa/FaGlobe";

import Head from "../../components/head.js";

import Layout from "../../components/layout.js";

const Motz = () => {
  return (
    <Layout>
      <Head title="George Motz" />
      <h3>George Motz</h3>
      <h4>
        <FaGlobe style={{ marginRight: 7, paddingTop: 5 }} />
        Brooklyn
      </h4>
      <p>
        <OutboundLink href="https://www.instagram.com/motzburger/">
          Instagram
        </OutboundLink>
      </p>
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1665863925/artnow/george%20motz/2.jpg"
        alt="George Motz, the Burger Scholar"
      />

      <h3>Interview by Edward Alvarez</h3>

      <p>George Motz is America's foremost expert on hamburgers. </p>

      <p>Author, photographer, filmmaker and self-declared home chef, </p>

      <p>George brings life and energy to his show Burger Scholar Sessions.</p>

      <p>
        It was a surreal experience talking to him, but also a great
        conversation.
      </p>

      <div className="video-container">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/WZeVSu48b54"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>

      <p>
        Edward Alvarez: I'm here with George Motz, he's the burger expert, he
        has the show Burger Scholar Sessions. I started watching you a while
        ago...sometimes I'd stay up late at night and I'd be stoned or whatever,
        you know, have a few beers, and then I'd watch your show talking about
        cheeseburgers and stuff. It was entertaining and you always seemed
        really super enthusiastic about what you were talking about. So that's
        kind of how I started watching you. I know that you used to have a show
        called Burger Land, between Burger Land and Burger Scholar, are those
        the only burger shows that you've done?{" "}
      </p>

      <p>
        George Motz: Well no, I was also a guest on The Burger Show. They
        actually came to me as they were trying to develop the show, The Burger
        Show, on First We Feast. Because they said, we need a resident expert.
        At first I thought they were asking me to host it. They said, are you
        available to be on our show? Yea, Burger Land ended and I'm not doing
        much with TV. They said, we do have a host who we love but he needs some
        advice, some help...They were actually talking about doing it in a
        restaurant or something. I said you know what, they weren't paying me
        and I said, I got a better idea, you just come to me and we'll do an
        episode at my house, and that became the very first time we used my
        house. You look at the backdrop- that really is my house, that's
        actually where I live. When you look at the background of the show-
        you're looking into my living room. My dining room, my living room, and
        out the door, out the window, into Brooklyn. You can see the church
        across the street, it's right here, it's all real. This is actually all
        the, there it is, this is the actual, kind of a mess right now, but this
        is the set. And it started like that because I just didn't want to leave
        the house. I wanted to make sure they came to me, just so I could save a
        little bit of time, because I wasn't making money on it. But when they
        got here, we had this great episode where I ran through a couple of
        regional classics. They made the show. I kind of forgot about it. About
        a month or two later someone said, oh it's airing, you know tomorrow,
        whatever. Oh it's too bad, I'm going to be on a plane to Brazil, I'm not
        gonna be able to see it when it drops. And the minute the plane landed,
        a string of texts saying, wow, great show, wow, great job. What is going
        on here? And one of my friends sent me this text, said, "You asshole.
        Burger Scholar?" What, what are you talking about? And without telling
        me, they re-named me, or decided to start calling me, the "Burger
        Scholar." My credits on the bottom of the screen, it says, George Motz
        Burger Scholar. Up to that point it was, a "hamburger expert" but I was
        never the "Burger Scholar." It was kind of a funny name and it's stuck.
        And then the next email that I checked that day was from I think, Food
        and Wine Magazine, or something. And it's, we have a column, we want to
        do a story about you, we want you in our column, and the column is
        called, "So You Want To Be A..." And we'd like to know what it takes to
        be a burger scholar. The world's gone crazy. And it was at that moment
        that my demographic shifted and went from just a bunch of 30 or 40
        something guys with glasses getting stoned at home, to everybody, from
        like 8 year old girls all the way to like 90 year old guys. And I'm
        very, very thankful for that, because it took off. And now I've been on
        every season of Burger Show at least once or twice a season. And then it
        also spun off during the pandemic- it spun off Burger Scholar Sessions,
        which now is about to go into its sixth season.{" "}
      </p>

      <p>EA: Oh I see. I think that you're also a burger historian. </p>

      <p>
        GM: Yea...I actually like the phrase scholar, because it shows that
        somebody out there is taking the burger seriously. I think you need to
        be scholarly to be a historian, you know? I like the word scholar...
      </p>

      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1665863925/artnow/george%20motz/4.jpg"
        alt="George Motz Burger Scholar Great American Burger Book"
      />

      <p>
        EA: Yea for sure, it is maybe a little bit more prestigious. So I think
        you're actually the first author that I've interviewed. You wrote two
        books, and I think you co-authored another book? So the two books that
        you wrote were Hamburger America and The Great American Burger Book. And
        then I think you co-authored All About the Burger: A History of
        America's Favorite Sandwich?{" "}
      </p>

      <p>
        GM: Actually Sef Gonzalez wrote that book, I just penned his foreword.{" "}
      </p>

      <p>EA: Oh okay, okay. Sorry about that. </p>

      <p>
        GM: I don't write a lot of forewords. And that was one of the ones I was
        willing to do because I like him, I trust him, he's a great guy, and he
        definitely is out there getting it right. I call him for advice all the
        time. So he's one of those guys that I keep very close, because he does
        know a lot about- specifically about the fast food burger business. So
        honestly that's one of the main reasons we've become such good friends.
        But I've written two books that have now been published five times. So
        Hamburger America has been published three times, it's been the original
        one, and then there's been two updates, two major revisions. And then
        The Great American Burger Book which came out in 2016, we just finished
        rewriting that one, and now there's a new edition coming out in the
        spring in 2023. So that's five. My agent asked me to do number four of
        Hamburger America, so that's coming too.
      </p>

      <p>
        EA: What's been your experience as a writer writing those books? Was
        that really difficult?{" "}
      </p>

      <p>
        GM: Oh yea. I wasn't really a writer to begin with. The only thing I was
        writing was I was penning articles, I had a blog at one point...And
        that's about it for writing. Beyond that I wasn't really doing much
        writing. But then I made a film called Hamburger America in 2004. I
        worked on it from 2001 to 2004, which is a long time ago now, that's
        over 20 years ago...When that movie came out somebody asked me to roll
        my research into a guidebook. It was actually Rizzoli, I don't know if
        you know Rizzoli books? They asked me this, can you do that? I said yea,
        I can try. And I did the research. I said, it's not a lot of burgers,
        I'm only talking about eight or ten places in the movie, I could expand
        to 20. And they said well, really you should have like a hundred. A
        hundred?! Whoa, I can't do a guide to a 100 places in a book. And then I
        picked up an agent, and the agent said, yea you definitely should do
        that. And I got on the road, Rizzoli dumped the deal, and I ended up
        going with another publisher to make Hamburger America, the first book
        of 100 different restaurants. And it was very difficult, because I had
        no idea what I was doing. Also back then, keep in mind, if you think
        about 2005 or six at this point, was there an internet? I don't think
        there was an internet. Maybe there was, maybe the burgeoning days of the
        internet, interwebs. There was no information out there. Even if you
        could go on the internet, think about how much more information is out
        there today, just not even yesterday, today on the internet, compared to
        what it would have been like in 2007. I mean there's nothing out there,
        a lot of conjecture, a lot of bullshit. So I really, I was alone out
        there. I just had to get on the road and figure it out. Also no one
        understood what I was doing. I mean, forget the writing part, the
        writing part was difficult. The only thing I could actually do really
        well was shoot, because I'm a photographer by trade. So for me to go out
        and shoot the burgers, that to me was like the big breath of fresh air.
        I was like okay, I can't write really well, I don't think I can write,
        but I can shoot. I know I can shoot burgers, I like to eat burgers,
        that's another plus. But I didn't know where to look, because no one
        knew where to look. There was one book out there that was sort of
        loosely about hamburgers called Roadfood by Jane and Michael Stern, and
        that became a bit of a road map for me, I used that as a guide. That was
        my guide, but even those reviews were thin. They weren't based on
        hamburgers, they're based on the experience of being in like these
        places in small town America. I was looking specifically for hamburger
        stories, like I had in Hamburger America the movie, which is eight of
        them by the way. So I got on the road and it was trial and error, it was
        massive fails across the board. I came up with a guide. I would say
        okay, to be in this book, I said it to myself, "To be in this book you
        have to have a burger on the menu for over 20 years, you have to use
        fresh ground beef, it has to be a great story. Those three things. You
        can't have those three things, I'm not interested." In Hamburger America
        the movie, it only profiled interesting hamburger stories. But then I
        realized I had to put some kind of a, you know, a template on what these
        100 restaurants would be like, and then I had to get on the road. And
        then I started eating really bad burgers and getting horrible leads. And
        I realized that the best leads were coming from like the guy who drove
        the rental car bus to the rental area, you know? The kid opening the
        front door at the hotel, helping me with my bags, and truck drivers,
        regular people. The people at the checkout counter at the Walmart, they
        were the ones who knew where to find burgers. "Experts" didn't know
        where to find a fucking burger, pardon my language, but they just, they
        had no clue, they had no idea. So I started to trust people who were
        actual eaters of hamburgers, not people who had an opinion about food.
        And that's the way Hamburger America came to be. And it was definitely
        difficult, to answer your question. Sorry it was a long answer. To
        answer the question: YES, very difficult to write a guidebook about
        hamburgers. But it's gotten a lot easier now, because by the time I got
        to book number two, Hamburger America 2 had a legion of fans all over
        the country that I call EBTs or Expert Burger Tasters. And what I would
        do is I would tap them for information. They're literally in every
        corner of the country, every region, everywhere out there. I've got
        friends, some of them have actually become close friends of mine. And I
        mean, where? You name it, Wisconsin, the Pacific Northwest, I've got a
        ton in LA, Miami. Sef Gonzalez is a perfect example, he's an EBT of
        mine. But I have others in Miami as well. And they basically, I refer to
        them as my First Responders to Great Hamburger Discoveries, and their
        job is to go in, they're on the front lines. Their job is to go in and
        say, Motz, you're gonna love this place, it's got history, it's got a
        great story, it's fresh ground beef...and it's been around for 100
        years. So I look to them for information now, and my job has gotten in a
        way a lot easier than that first book.{" "}
      </p>

      <p>
        EA: Oh I see, yea. I guess either way it's hard to write a book. Some of
        what you said reminds me of one of these quotes that I wanted to ask you
        about. I was watching an interview with Marco Pierre White, the chef. He
        said, "A story is way more important than a recipe, a recipe can confuse
        you but a story can inspire you. And that's what life's all about is
        stories, because it's those stories which inspire us to go and achieve
        what we have to achieve in life, it's those stories that make us dream,
        and success is born out of dreams." So do you think you would agree that
        maybe sometimes the story has more meaning than just following a recipe
        or something like that?{" "}
      </p>

      <p>
        GM: I think it makes it easier. That's a great quote, but to also quote
        Andrew Zimmern who wrote the foreword to my cookbook. He said, "Food is
        great, but food with a story is even better." That's true, if you think
        about it. All of the burgers in my book have a story, they have an
        origin story, they have deep history. And what his argument was, that
        makes the burger taste better, when you can equate it, relate it to a
        piece of history, or something you might even not have known. For sure,
        I think, unquestionable. I mean, one of the stories I was just telling
        the other day was that, people don't realize that the actual patty melt
        itself- the patty melt has become diluted over the years. And people
        think patty melt, it's just no bun, it's bread...No, no, it's not a
        patty melt. The patty melt is one very, very specific thing: it is a
        hamburger patty in between two pieces of, specifically rye bread, with
        Swiss cheese, and grilled onions in there as well. That's it. No sauce,
        there's no white bread, not American cheese. Patty melt is just that one
        thing, and we know that because it was invented at a place called Tiny
        Naylor's on Sunset Boulevard in Los Angeles, the corner of Sunset and La
        Brea. That's it, that's where it came from, that's what he originally
        invented and that's why nothing else is a patty melt except for that.
        That's a great story. And that story actually it authenticates the
        moment, it authenticates the burger, and also I think makes it taste
        better. When you eat the real thing, you say to yourself, oh wow, okay I
        physically just ate a piece of history. If you eat it with American
        cheese that's not really, that's not a patty melt and you know this
        now...So there is definitely something to be said for a story making
        food taste better, for sure.{" "}
      </p>

      <p>
        EA: Yea, and the interesting thing about people is that sometimes people
        just want to tell their stories. And I noticed when you were doing
        Burger Land you were kind of going around interviewing different people
        who were running burger places right? Was that an interesting
        experience?{" "}
      </p>

      <p>
        GM: Oh well I mean for me, of course it was, because these are my
        heroes. When I first started writing, when I first did Hamburger America
        the movie, then made the book for 100 different burger joints, the
        guidebook, I started to realize it was equally about the people as it
        was about the burger. I always tell people, you know, people make
        burgers, so you have to focus on the people as well as the burgers.
        Don't just walk in and say, that's a great burger. Meet the people too.
        But it was interesting because, for a bunch of different reasons, one
        was that, a lot of times when you approach someone like that and they've
        been doing the same burger for 100 years, they're the grandson or the
        granddaughter of the person who invented that burger or opened that
        burger joint, in most cases especially back when we filmed Burger Land,
        they had no idea of their own value. They just thought, well we make
        food so what, what's wrong with you, why are you taking this so
        seriously? And it was my job in the last 20 years to make sure these
        people are appreciated and make sure that they appreciate themselves.
        And that's something that, it's really hard to do. It's much easier to
        get someone to say, hey you know what I love that burger, or Motz show
        me a good burger, where am I going, and I'll tell them, that's easy to
        do. Convincing the people who make the burger that they're important is
        much harder. I think the balance is actually tipped now at this point
        where the hamburger is so popular the people who run these restaurants
        for the first time are beginning to appreciate their own value. It was
        very difficult in the beginning. But that's a lot of fun to go around
        and do that kind of stuff, because you're talking to people who are
        telling you their histories. You're trying to get them to not tell you
        any secrets, they always slip sometimes, and I'm always like, no no no,
        keep your secrets, I don't want to hear that. But these people are my
        heroes, my Hamburger Heroes.
      </p>

      <p>
        EA: It seems like such an experience for sure. I'm wondering did you
        ever meet Anthony Bourdain?{" "}
      </p>

      <p>
        GM: I did yea, I was actually at a bunch of events with him. The joke of
        course was that I always had to reintroduce myself to him, even though
        we were both like the "celebrities" in the room. You know, I was never
        obviously the celebrity status that he was, but I was on panels with
        him, and he'd go, he'd point his finger at me- and I said "George,
        burger guy." He would just go, "Oh right, burger guy." And he'd walk
        away. And that happened three times, three times where he did that to
        me...He did actually give me a quote for my book, it's on the cover of
        my book. He gave me a quote a long time ago. His agent was a really good
        friend of mine, so we crossed paths often...I'm not gonna lie when I say
        that when we made Burger Land I was channeling him for sure. And I would
        have loved to keep going and do another show that would have been closer
        to what he was doing...the first Show No Reservations, I did emulate his
        style. In fact we looked at episodes of that show to emulate and
        basically copy for Burger Land.
      </p>

      <p>
        EA: Yea I see. Even though he forgot your name at times that still seems
        kind of cool that you were kind of in his orbit or whatever.{" "}
      </p>

      <p>
        GM: Yea well we were both at the same network for about 10 minutes, we
        were both the Travel Channel. He was actually on his way out and I was
        just coming into Travel Channel, so he knew what was going on, but he
        was very aloof. He was very purposely aloof, that was his whole thing. I
        heard somewhere that he only had 10 friends, that was it, and everybody
        else was just in his way, which is fine. I heard a lot of stories about
        him, I've read about him, you know we all love the work that he did. He
        was a great writer, I'm not a great writer, he was a great writer, he
        actually could write really well.
      </p>

      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1665863925/artnow/george%20motz/1.jpg"
        alt="George Motz, the Burger Scholar"
      />

      <p>
        EA: So you're saying that you're doing a new season of Burger Scholar.
        That's the only new show that you're doing right, for burgers?{" "}
      </p>

      <p>
        GM: Yea that's it right now. I'm getting ready to go on a very large
        project I can't talk about right now, but something big is about to
        happen. Big, big, big. So we're just sort of waiting and seeing what
        happens with that before I do anything else. But the idea is that the
        sixth season of Burger Scholar Sessions is supposed to be a partial
        travel show. Kind of like Burger Land, or kind of like you know Parts
        Unknown, where I get out there and instead of just standing in my living
        room, which people actually like- we've got a lot of feedback, the
        minute we moved out of the living room and did a show in my backyard,
        people complain, like why isn't he in his living room, I like him better
        in his living room. Really? Okay fine, we'll go back in the living room
        again. So the people still want to see episodes shot in the living room,
        right here. But we're planning on a road show. Some of the episodes, not
        all of them, some of the episodes are out there on the road. Maybe
        traveling to Europe to try to find history of the burger and travel back
        with it and that sort of thing. A lot of big ideas happening but I can't
        talk about that.
      </p>

      <p>
        EA: Right, right. Yea, I can understand the appeal of your living room.
        It is so picturesque in a way, maybe it doesn't seem like anything
        special to you because you see it all the time, but I do notice every
        time I watch your show on YouTube I'm just like kind of blown away by
        your living room.
      </p>

      <p>
        GM: Honestly we didn't set it, we didn't dress it or do anything.
        Literally the first time we did the show here, we set up and they're
        looking left, looking right, they're like hey can we move that? Nah,
        keep it, keep it, it's fine. Everything is exactly the way it is. I
        mean, I clean it up a little bit, you can tell obviously right now I've
        got recycling ready to go out over here, but for the most part
        everything is here. I've got my tumbleweed on the wall, it's all here.
        The background is really just me. And I try to explain to people like we
        didn't set this thing. In fact we have a call tomorrow to talk about
        doing a live Burger Scholar Sessions at a conference just for fun, and
        we're gonna do a live show with a live audience, and then we're talking
        about putting up behind me pieces of the set, like the tumbleweed.{" "}
      </p>

      <p>
        EA: I was going to ask you, is it rare to get sick from eating a
        hamburger? Have you ever gotten sick from eating a hamburger?{" "}
      </p>

      <p>
        GM: Sure yea, I've definitely gotten sick from eating a hamburger. Sure.
        Usually it's not the hamburger though, usually it's something else on
        the hamburger. Like, believe it or not, like dirty vegetables or old
        vegetables, lettuce, usually it's some idiot didn't clean a knife and
        cut through you know mayonnaise or something. But yea, that's an
        interesting question...Keep in mind, I've eaten a lot of burgers, for
        the most part I have not gotten sick to be totally honest, I have not
        gotten too ill. And the times I've really gotten sick have been when
        I've been eating things other than hamburgers I feel like. You know, I
        travel all over the world, and I've eaten some wackadoo foods out there
        man. If I do get some kind of food poisoning or I feel sick it's because
        of something other than the burger.{" "}
      </p>

      <p>
        EA: Oh okay so it almost never has anything to do with like the meat or
        anything like that?{" "}
      </p>

      <p>
        GM: No...I know a lot about physiology and how those things work, and
        I'm pretty sure that if I've gotten sick it's honestly it's been because
        of some handling, some dirty handling, it's not because of- you know,
        it's funny, raw meat, it's really hard to actually get sick from raw
        meat unless somebody has like egregiously left meat out for days. But I
        gotta tell you, honestly, you go to other cultures outside of America,
        there's a great thing in the north of Brazil where they take a piece of
        meat and they just leave it out in the sun to dry, to dry it out. It's
        just dried in the sun, not dried in like a dehydrator. It's actually
        dried in the sun, with the dirt and everything out there, and then they
        cut it up and eat it. No, I've never gotten sick from meat.{" "}
      </p>

      <p>EA: Okay, okay. That's a relief right there. </p>

      <p>
        GM: Not true, I shouldn't say that. There is one time I did get sick
        from meat and it was, I know exactly what happened, it's because I was
        eating a dry aged steak. We're all messing around at the end of the meal
        and started chewing on the bone, of course not realizing that all of
        those bacteria are actually, you know, they shave the bacteria off of
        the meat before they sell it and you can cook it...it's exciting
        bacteria, but it's not the kind of stuff you want to have in your
        stomach if you can avoid it, so we chewed the bone and we all got sick.{" "}
      </p>

      <p>
        EA: Oh I see. So in terms of a hamburger being a little bit undercooked,
        I mean it's just like eating rare steak right?{" "}
      </p>

      <p>
        GM: Well it's like eating raw steak or sushi or anything else. As long
        as the product is fantastic, even like marginally fantastic you're gonna
        be fine, totally fine.{" "}
      </p>

      <p>
        EA: Okay, yea. It's just something I've been wondering about, I think
        about it sometimes, but maybe I've been overthinking about it, that's
        what it seems like. So that's good to hear actually.{" "}
      </p>

      <p>
        GM: If you know where your meat is coming from, you're in great shape.{" "}
      </p>

      <p>EA: So are you ever gonna appear on Hot Ones? </p>

      <p>
        GM: That's a great question. I don't think so. The joke I like to say is
        that I'm not famous enough, because you know I'm not Beyonce and I don't
        have 17 billion followers. I have a very organic social media thing
        happening and I'm happy with it, don't get me wrong. I'm happy with my
        following, but I'm not as famous as they would like me to be. They love
        me, it's pretty obvious that First We Feast, they appreciate my
        presence. We've had Sean on my show once or twice, I can't remember now,
        but both times we were on the show, you know, I had some hot sauce and
        we put The Last Dab on a burger, and it was fun, it was hot as hell,
        that's for sure. I would love to be on the show and I would definitely
        do it if I was asked, but I haven't been asked.
      </p>

      <p>
        EA: Yea, I watched that episode where you were with Sean Evans and then
        you said you weren't famous enough to be on his show. But I think you
        would be such a great guest on that show. They should really have you on
        there because your personality and everything, like you're perfect, and
        then you talk so well with Sean Evans, you guys have such good on-screen
        chemistry or whatever. You'd be like the perfect guest.{" "}
      </p>

      <p>
        GM: I think I kind of overwhelmed him when he was at my house...He's
        also a really good guy by the way, he's a very, very, very sharp guy.
        Obviously he has to be. But he's also a super nice guy, you know, a lot
        of those guys you imagine them being like a super aloof, like Bourdain
        style aloofness. But he's not, he's actually a really, really good
        generous host and good guy.
      </p>

      <p> EA: Yea for sure. I get that impression from him also. </p>

      <p>
        GM: I've made some jokes, I was just on the episode of Snacked with my
        daughter and I made the joke again, you know, never going on Hot Ones,
        not famous enough. Anyway, go ahead.
      </p>

      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1665863925/artnow/george%20motz/5.jpg"
        alt="Hamburger America George Motz"
      />

      <p>
        EA: Were you ever inspired by Jack Kerouac to travel across America?{" "}
      </p>

      <p>
        GM: That's a great question, thank you. No one's ever asked me that
        question before, you're the first person in all these years to ask that
        question. And the answer is a definitive, resounding YES. I can expand
        on that. Jack Kerouac was I think, a prophet, as far as I was concerned.
        I'm not a very religious guy, but I do believe that there are people out
        there who can guide you, in sort of, in almost like a religious way. I
        was young when I read On The Road, which I still have here, hang on a
        second, I think I still have my original copy. This is my original copy
        of On The Road. It's well worn, it's actually, I have more than one copy
        now, I've actually started collecting some of them. To me, it was a
        Bible, in a way. I mean, I started reading this when I was a sophomore
        in college and I got through the first chapter and I went holy fuck what
        am I reading, this is amazing. One big run-on sentence of just
        gloriousness, you know. And I looked at my roommate, I said we have to
        get on the road and he was like what are you talking about? I said, we
        have to go on a road trip, I'm inspired by this book, we need to go on
        the road man. On The Road, it says right there. He thought it was like a
        self-help book. I said, no, no, no, it's Jack Kerouac, it's a novel,
        it's in the 40s, it's awesome. And I started to learn that the
        beginnings of the Beat Generation, the beginning of hippie culture, the
        beginning of all this stuff, was right here. And so it definitely
        inspired me to get on the road. I grew up a very sort of sheltered, I
        shouldn't say sheltered, but you know, nice suburban life on Long Island
        in New York, and never really got out much. My parents didn't travel all
        that much, so I never really got out. I went to the beach, had a great
        time at the beach, I still love the beach. I went to the city once in a
        while, but pretty much grew up in Suburban New York. And when I read
        this book, I said okay I'm out of here. I took my car, took my dad's
        Mobile credit card, and took off. And I couldn't stop, I went across the
        country probably six times, back and forth. And didn't quite lead the
        same kind of like debaucherous lifestyle that these guys led with my
        Mobile credit card, but I definitely kind of, I felt it, I got it. I did
        some of those things they do in the book, where they're trying to save
        gas and they're driving in the mountains so they coast downhill. I
        actually did that, I did. I put the car in neutral, turn the car off,
        and just cruise down these highways at 100 miles an hour with no gas,
        the car wasn't on. And then you get to a certain spot you know and ease
        it back in, turn the car back on again, and I did that because Jack
        Kerouac and Neal Cassady did that. That's a true story. I discovered
        America because of this book, because this book got me on the road, and
        this book also inspired me to get out there and see America, and at that
        point I became fascinated with America. So much to the point I actually,
        I get kicked out of school- another whole story, I got kicked out of
        college, and when they were asking me, you know, said you can get back
        in if you choose a different major. So I chose history with a
        concentration on Modern American history, this right here, which is
        exactly where I wanted to be in life, was to be out there in America,
        not in suburban New York, but to be on the road in America discovering
        the things that people were afraid to go near, the parts of America that
        seemed inaccessible. And so when I started working on the movie
        Hamburger America, I said, oh my god, I'm on the road again, this is
        amazing. And I haven't stopped since, and I truly do appreciate, I try
        to get people to go on road trips with me still to this day. I'm 53
        years old, I'm still, come on we're going to fly to Kansas City and do a
        thousand mile loop, let's have some fun, we're gonna see amazing things,
        things you never would have seen before. People who have said to me, oh
        I hate Ohio. I'm like why do you hate Ohio? It seems boring. I'm like,
        come with me, we're going to Ohio, you're going to see how amazing Ohio
        is.{" "}
      </p>

      <p>
        EA: Yea Kerouac was a big influence on a lot of people. There's so many
        things to say about him, like you said just one big run-on sentence. He
        was energized with the language and it was all important to him. And
        there's that whole legend that he wrote it on a long scroll of paper.{" "}
      </p>

      <p>GM: He did. </p>

      <p>
        EA: Do you think success is more luck, or do you think it's more hard
        work?{" "}
      </p>

      <p>
        GM: I think it's actually a combination of both, but there's no question
        about that. I gotta tell you, this sounds cliché, but it really comes
        when you're not looking for it, it really does. We didn't know that the
        Burger Scholar Sessions was going to be as successful as it has been. I
        would say arguably it's my most successful thing I've done, because now
        everyone's going back into my catalog and finding my books and movies,
        all that kind of stuff. But it's because of Burger Scholar Sessions, and
        that was literally born out of the pandemic. We were trying to find
        something to do during the pandemic, so they said, can we send you
        cameras and some lights and some audio equipment and can you write your
        own scripts and act, all this stuff...We were bored, this was the
        beginning of the pandemic. We said sure that's a great idea, let's make
        some movies, make some content. We shot the first episode, sent the
        footage to the network, and they sent me this edit back, and I'm like,
        you guys are crazy, that's not what I shot. Is this what I shot? Because
        the editing was totally different than the actual, I felt like it was
        different than what we gave them. We had a very sort of slow, easy going
        show, and they cut the thing up: all the parts of me burning myself- and
        actually created a great show out of the crazy footage. So it was a
        success, but it was a surprise success. So I think you definitely can
        work at being a success. You have to. You can't just sit around and just
        hope that one day someone's gonna discover me, you have to actually work
        hard at it. So I do think success is a lot of hard work, for sure, and a
        little bit of luck.{" "}
      </p>

      <p>
        EA: So going back to when you were talking about how you started your
        shows and everything, was it something that you always wanted to do? In
        the past, did you look at your future and did you think like, I'm gonna
        have my own show? Or was it kind of more like, things just kind of fell
        into place?{" "}
      </p>

      <p>
        GM: Things just kind of fell into place. I definitely, absolutely did
        not ever think that at all. I never worked towards a show. I was the
        other side of the camera for many years. I spent 28 years as a union DP.
        Local 600 Director of Photography in New York City. So I was always on
        the other side of the camera, and then at one point I was asked to do a
        show, Burger Land. I said sure we can do this, but keep in mind, I've
        never really been in front of a camera before. So we shot a test
        episode, and they said, you're pretty good, you can do this. And we
        ended up making a show, and that was the first time I was on camera. And
        people thought, oh, you seem like you're a natural. I'm like, I'm
        definitely not a natural. But I do know, for example...we did the show
        Burger Land- there were five cameras all around me all the time. I could
        look any direction there was a camera somewhere, which is a little bit
        unnerving, but at the same time also kind of comforting that someone's
        gonna get the shot. So you know, somebody out there got the shot...but I
        could look down the barrel of a lens on the camera and I could tell
        where they were in the lens. Are they zoomed in, are they wide, you
        know? Because I knew from being a DP, and being a camera guy, what the
        integrity of the lens should look like...And I wouldn't speak my line
        until I knew that the edit needed a zoomed in shot, or a wider shot, and
        I could tell right away from looking at the camera- like you need to go
        wider, and they're like, how did you know that? Also when talking to
        production...I know how production works behind the camera. I know that
        there's a producer thinking, "we got to get this shot wrapped up," and
        there's a director saying, "I didn't get the shot yet," and there's a
        camera guy saying, "yea but we're gonna wait till the sun's in the right
        spot." And everyone has different ideas, and I understand of course the
        most, I understand what's going on behind the camera, more than most
        people who are in front of the camera, but I also understand
        specifically what the DP is thinking. And I look at the producer, look
        at the director and say, listen to the DP. Because the DP wants to make
        me look good, that's their job is to make me look good, and also to make
        you happy mister producer, make you happy mister director, let's all be
        happy, but listen to the DP.
      </p>

      <p>
        EA: Well I think you are a natural at what you do, and I think maybe
        you're meant for everything that you're doing, and I guess like you said
        it has come from hard work, but I think your personality is just kind of
        meant for what you're doing.
      </p>

      <p>
        GM: Well, thank you. I get a lot of it from my dad, I think. My dad is a
        very personable character, to put it that way. People do liken me to my
        father, which is for better or for worse, but he's definitely, he's a
        force of nature. He's done a lot of things in his life, but one thing
        he's always been very good at is communicating with people, he's been
        very good about that.
      </p>

      <p>
        EA: Communication is really important, language and everything. Can
        people officially refer to you as a chef?{" "}
      </p>

      <p>
        GM: Well, I think, if I go near that title at all it's usually, "home
        chef" so I would put the word home in front of it. Because I'm not a
        trained chef, if you say "chef" I think right away there's an
        implication, people suppose that you have gone to school for whatever
        you're talking about. I did not go to school to be a chef, or a cook or
        anything, except for my mother's kitchen. And to me that's all you
        really need honestly, so in that case, I'm a home chef, I'm a skilled
        home cook, I know what I'm doing in the kitchen for sure, but I wouldn't
        say I'm a "chef."
      </p>

      <p>
        EA: Yea that's funny, that kind of reminds me of something that Marco
        Pierre White said recently that he's just more of a home chef these
        days.
      </p>

      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1665863925/artnow/george%20motz/6.jpg"
        alt="George Motz Burger Scholar"
      />

      <p>
        GM: But he's trained though, he actually went to school...but at the
        same time there's so much information out there, that just about anybody
        could be as good as someone who went to cooking school in a way. All the
        different content out there, if you really just follow...I mean
        Christina Tosi right now, I think every week I believe since the
        beginning of the pandemic, she still does it, she puts up an Instagram
        post with a bunch of ingredients...And then she has I think an Instagram
        live thing where she teaches people how to cook from those ingredients
        they just picked up at the store the day before. So in a way, sure
        school is important, I think, I'm not going to say it's not. But at the
        same time there's so much information out there that anybody, if they
        pay attention, can actually, really be a success in the kitchen now
        pretty easily just from watching Tik Tok. My daughter still makes Tik
        Tok recipes all the time, and usually at two o'clock in the morning,
        which is always fun.
      </p>

      <p>
        EA: So I think from one of your Burger Scholar episodes you said that
        you ate 12,000 hamburgers to do research for what you were doing, is
        that true?{" "}
      </p>

      <p>
        GM: Yea, I think it's actually higher than that. I think we're somewhere
        around the range of like 15,000 now, since the beginning of research,
        technically like 20 something years ago, you know, for sure. Maybe
        closer to 14, 15 somewhere in there. Some burgers I don't finish.
        Sometimes I'm on the road I can eat 30, 40 burgers in a week. Sometimes
        I have two or three burgers a week, you know depending...
      </p>

      <p>
        EA: Research is research and if burgers are the things that you're
        writing about and talking about, it makes sense that you would go the
        distance with it. I really want to ask you, have you ever had any
        ghostly, supernatural or an out of this world experience?
      </p>

      <p>
        GM: Hmm, not really. I've been watching Stranger Things in the last
        couple of years and I think I do, I think I see things or hear things,
        but that's related to the show I think, which by the way is a fantastic
        show. I'm having a lot of fun because...it takes place in exactly my
        youth. So that was similar to the suburban world I lived in, where
        everybody was on banana seat bikes, with the basket in the front and the
        Radio Shack walkie-talkies, I think I had those. I was part of D&D
        culture you know, so to me that show is very, very familiar. And I get
        it. But no, supernatural- no not really sorry.{" "}
      </p>

      <p>
        EA Yea I've watched Stranger Things. It's good, it's a little bit scary
        at some points, but you know it's entertaining.{" "}
      </p>

      <p>
        GM: Yea, definitely scary. Right now we're in the middle of season four,
        my son and I are watching it.
      </p>

      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1665863925/artnow/george%20motz/3.jpg"
        alt="George Motz, the Burger Scholar"
      />

      <p>
        EA: What do you think when people say that bread is unhealthy? Do you
        have an opinion on that?{" "}
      </p>

      <p>
        GM: I think eating a lot of bread is unhealthy. Bread can be unhealthy,
        sure. But bread is also the staff of life, as far as I'm concerned.
        Depends on how you eat bread...it's kind of a tough answer for me
        because I'm not eating a hamburger without the bun that's for sure.
        That's the envelope for the good news that's coming, that's the perfect
        delivery system for portable food, is bread. So no, I'm not going to say
        that, but I understand that if you eat a lot of bread, sure, it's
        probably not great for you. I think in the states we have an interesting
        issue we're using enriched flour for bread, which is actually, that is
        not good, and I wish that didn't exist, because you can buy great white
        flour if you go to other countries. It's kind of hard to find it in
        America right now, bummer.
      </p>

      <p>
        EA: Yea, staff of life, that's a really good point. Have you ever
        thought about opening a restaurant?{" "}
      </p>

      <p>
        GM: Yes we have. That's actually what's happening right now, so that's
        why I didn't want to talk about it, I can't do details, but we're
        underway with a very, very interesting, we're about to have really big
        news on a restaurant, just so you know.{" "}
      </p>

      <p>
        EA: That sounds amazing. Yea, you don't have to say anything, it was
        just in my list of questions. The other thing I was going to ask you is,
        do you think there's any advantages to a flat top grill compared to the
        other grill that has the horizontal bars?{" "}
      </p>

      <p>
        GM: Well, they're just two very different things. It's like a
        screwdriver or a hammer. What tool do you need to get to where you're
        going? They are two very different ways to cook. I personally prefer the
        flat top because I like the product that you can create, specifically
        when it comes to a hamburger with a flat top. Sort of a one-trick pony
        when you're doing the grill because you're looking for the Char-Broil
        taste of a burger, but I think you can do so much more on a flat top, so
        I prefer a flat top.
      </p>

      <p>
        EA: Oh I see, yea. The other thing I was going to ask you is, do you
        have any thoughts about fame? Like being famous, being a celebrity, do
        you have an opinion on that?{" "}
      </p>

      <p>
        GM: Yea, I mean, you know, it's not easy. I was flying back from LA the
        other day and three people came up to me and said "I'm a big fan of your
        content." This was nice, so nice to hear that. The one advice I would
        give to anybody who is in that moment, or if they're already very famous
        and they're not thinking of this, is just, be nice to your people, be
        nice to them. It's amazing how many times I see people who are famous
        just ignore their fans in public. I make the point of saying to every
        single person who says hello to me- thank you, thanks for saying hi,
        that really means a lot to me, thanks, thanks for recognizing me, you
        know, which is all you can do. One thing that's really weird about fame
        is that if one person recognizes you and says something, that's kind of
        crazy. Because, think about, what would you do? Would you walk up to
        Johnny Depp or somebody and say, hey I love your movies, I love Rango?
        No, you're not going to do that, but there is somebody who will do that.
        And so for every time that someone walks up to him and says, you were
        great in Rango, there's another 100 people who are walking all around
        him not saying anything. That's the weirdest part because I'm thinking
        the same thing, if I'm on a plane and some guy actually walked up to me
        and said hello- who else is in that crowd who recognizes me? It's kind
        of weird, and one thing I'll say, people have got to stop taking like
        sneaky cell phone shots. Just say, can I take your picture? And I'll say
        yes. I'm always freaked out by people who are like hiding behind
        people's heads...Just say hello, it's okay. I'm not gonna bite or tell
        you to fuck off. So yea, it's very bizarre, it's very strange sensation,
        very bizarre. I can't walk out my door without people, and I have a lot
        of friends in the neighborhood here obviously, but still people do
        recognize me now, it's all because of the damn Burger Scholar Sessions.
      </p>

      <p>
        EA: Yea, it could be really intense seeing somebody that you admire or
        look up to, sometimes it could be a difficult experience, but it's
        really cool that you are easy going about it. That seems maybe like the
        right approach.{" "}
      </p>

      <p>
        GM: I can't go onto Hot Ones because I'm not famous enough. It's a good
        level of fame I have right now where I actually can go on the street
        still and people don't recognize me...You know that's great, I mean if I
        was somebody, if I was a Johnny Depp- I don't know how he gets through
        the day, I don't know how he walks around...I would never want to have
        like Beatle Mania Fame, that kind of thing...{" "}
      </p>

      <p>
        EA: Oh yea that kind of reminds me of the writer Thomas Pynchon, like
        nobody has seen him before, and I guess he's probably just somebody who
        wants to go on with his day without people pestering him or something.{" "}
      </p>

      <p>
        GM: Yea, I mean a lot of those guys are like that, a lot of some of the
        best writers out there, you know.{" "}
      </p>

      <p>
        EA: Have you ever experienced doubt? Because you always seem so
        confident in everything that you do. I talked to an artist for example
        recently, and I asked him the same question, if he ever experiences
        doubt when he's drawing something. He said no, that he always knows what
        he wants to do. Is it the same with you? Like it doesn't phase you, or
        you don't have doubts, or something like that?{" "}
      </p>

      <p>
        GM: No I definitely have doubts, often. I would say probably...most of
        the time I have no idea, I feel like I don't know what's going on,
        because what happens, like I go into a weird place. I kind of, I
        disassociate, I remove myself from myself for the time that we're
        filming and I sort of go on some weirdo auto-pilot. And I can't really
        describe it except that I do have a director who's feeding me lines, or
        at least, he's you know, we have a Zoom call, and he's talking to me
        about, don't forget you were gonna say this. Oh yea. So I read a script
        beforehand and then I'm on camera trying to figure out how to make the
        burger, you know, hit my cues, all that kind of stuff, and remember what
        I was gonna say. If I forget something then I'm fed the line, but it's
        sort of a weird out of body experience where I never really know what's
        happening until I look at the edit and go, oh we got it, okay, fine, I
        guess I did do a good job. So I feel like I'm constantly in constant
        doubt of what's going on, even though I have all the information in
        front of me, I've written it all down before, I know the history so well
        of all these burgers and all the methods. It gets a little complicated
        because we have to, you know, we're not just telling a story on camera,
        we're telling an accurate story and we're also now trying to recreate
        the burger while I'm telling the accurate story and trying to keep it
        lively at the same time, so it's interesting. So I do have doubt for
        sure. I think it's actually what drives me, it actually makes me work
        harder, because a perfect example is an episode we did where we did
        successfully recreate the White Castle slider, and I think I said it on
        camera, it took me five tries to get that burger right. Literally I had
        to recreate the burger, it wasn't until the fifth time that we did it
        that I figured out the method of what they were trying to, how they
        actually pulled it off. There's no written recipe for the White Castle
        slider and we had to get all those things together, the cooking surface,
        the type of beef, how the beef was cut, prepped, the seasoning, all this
        crazy stuff. And it finally worked out actually on camera, was the fifth
        time we did it, and it was the only time I got it right. So I had a lot
        of doubt going into that for sure, but it worked. I think doubt drives
        you, it keeps you moving forward, so it's okay.{" "}
      </p>

      <p>
        EA: Well that's a really good point actually. So I was talking to my
        friend last night. He's vegan and I was telling him like I'm gonna talk
        to this cheeseburger expert. He said, ask him what he thinks about
        veganism. So I guess maybe that'll be my next question here.
      </p>

      <p>
        GM: I think there's a place for veganism, just not in my body right now.
        Vegans are tough, vegetarians are a different story. I mean I'm not
        either one of those things. I eat healthy, I'm a very healthy eater. I
        eat a lot of crap too. So I say healthy meaning I have a balance. A lot
        of people who are my contemporaries don't eat any salads... Salad is
        what helps you maintain a normal, you know, flow through your body.
        Salad is very good, I mean I've had some great salads that rivaled some
        burgers actually. So I think veganism is too strict; honestly nobody
        wants to hear this, I think veganism is pretty unhealthy actually,
        because there's no balance at all. There's zero balance. I think
        vegetarianism is a much healthier way to go. I do practice a healthy
        lifestyle, as much as I possibly can.
      </p>

      <p>
        EA: Right. Veganism is a little bit intense. I'm not a vegan myself, but
        I guess to each their own. What you're saying makes sense also, there
        has to be some sort of balance. And then the other thing I was gonna ask
        you is, you don't eat cheeseburgers all the time, like you eat other
        things as well right?{" "}
      </p>

      <p>
        GM: Yea, I just love all food. I love to study food ways- in the way
        that people make food, and people get food. I'm a big fan of like where
        primary source materials come from, mozzarella cheese, you know, tuna,
        whatever. I'm a big fan of trying to figure out the very specific food
        ways, and I also like to recreate recipes that people have that are very
        simple and great. So to me, yea, I eat everything, just about every
        single thing out there I will put in my mouth at some point.{" "}
      </p>

      <p>EA: Do you think you're ever gonna write another book? </p>

      <p>
        GM: Yes, well I'm working on the cookbook right now... the cookbook it
        comes out in 2023 in the Spring. And then we're working on the guidebook
        for hopefully 2024, but outside of that, working on a TED talk right now
        so TEDx asked me to do something, so that's about the only thing I'm
        working at right now, writing wise. But I'm sure I'll make another book
        at some point, because my agent said, once you get to book number three-
        get ready for four, five, six and seven.
      </p>

      <p>
        EA: Yea that makes sense. Did you ever read the book The Dharma Bums
        from Jack Kerouac?
      </p>

      <p>
        GM: Yes I did, yea of course. I read all of Kerouac's stuff. I got that
        up here too somewhere, there it is. Doctor Sax...I read them all...
      </p>

      <p>
        EA: I had a really interesting experience meeting Gary Snyder who was a
        friend of Jack Kerouac, he became that character Japhy Ryder in the
        Dharma Bums. And it's such a kind of heartbreaking story, he signed my
        book, "Japhy Ryder is not Gary Snyder" that was his signature. And then
        like about a week later I lost the book. Have you ever had some sort of
        like, actually, maybe I'm gonna rephrase the question. Have you ever had
        any coincidences in your life? Sometimes it's called synchronicity.
      </p>

      <p>
        GM: Sure I've had tons all the time. Specifically related to Jack
        Kerouac I had a very interesting synchronous moment. I was going to
        Paris for the first time, I think I was 20, 21 years old. I'd just been
        on the road back and forth, and I was waiting at the gate and I hear
        "Allen Ginsberg, paging Allen Ginsberg." And I was like holy shit, Allen
        Ginsberg was on my flight to Paris, that's pretty cool, okay sure.
        So...sure enough he's on my plane, I look over he's sitting on my plane,
        and the plane lands, kind of forgot about it, moved on. The next day I'm
        standing at the Shakespeare and Company Bookshop, I don't know if you
        know what that is at all, Shakespeare and Company back then, was the
        only English language bookshop in Paris. So all the expats had to go
        there to get their stuff, even to buy their own books they'd have to go
        there, that's where they went. This has been around forever and ever and
        ever. And I just wanted to go to the store, I wanted to walk in the
        Shakespeare and Company and be part of that culture, where like you
        know, where Fitzgerald shopped, and where Kerouac was obviously there,
        and all those, I mean, Alice B. Toklas, you know, I'm sure she was in
        there as well. So I walked into the store and I'm looking around,
        there's this guy wearing this like red velvet suit, head to toe red
        velvet suit, and he's flipping around the store, going like, "Well,
        Allen's supposed to be in town, I think his plane came in yesterday."
        And I said, "actually if you're talking about Allen Ginsberg he was on
        my flight yesterday." He's like, "he's in town? He's supposed to come to
        my house. Where is he? We're still having a party tonight, you should
        come to the party." And I was like, "what? Are you inviting me to a
        party with Allen Ginsberg and you?" It was the son of the guy who
        started Shakespeare and Company the store, he was the owner, a very
        famous guy I found out later on, I didn't know he was at the time, but
        the red velvet suit gave it away. He invited me to the party and I said
        no, because I was actually leaving town, I was going to the south of
        France, but I'm kicking myself I didn't go to a party with a bunch of
        the old writers.{" "}
      </p>

      <p>
        EA: Right. Yea, there was a Shakespeare bookstore in Berkeley when I was
        there, but I don't know if it's the same one, maybe they're part of the
        same chain or something. That's really interesting. That's quite a story
        for sure. Anyway I guess we could start wrapping this up, I know that
        you have some things to do...But yea, my poetry teacher in college he
        ended up meeting Allen Ginsberg in Brooklyn, and he had some interesting
        stories and stuff. He said that Allen Ginsberg supposedly said his best
        writing came from his journals. So I guess there's something there, I
        don't know if you keep a journal but I found that sometimes it does help
        with writing. I don't know if you keep a journal in your travels and
        stuff.
      </p>

      <p>
        GM: The journal that I do keep is Instagram. Not surprisingly if people
        look at my stories, that is my journal. I post on stories every day and
        I also post in the feed once in a while if I've got something going on
        but for the most part it's my stories. So if I want to figure out, you
        know, what did I eat last time I was in this restaurant? I could just
        scroll back through my archive and go, oh yea, that's exactly what I
        ate. So I do sort of keep a journal just it's a photo journal.{" "}
      </p>

      <p>
        EA: It's been a great experience talking to you, and I really appreciate
        you taking the time to talk to me.{" "}
      </p>

      <p>
        GM: Great questions, really thank you very much, good talking to you.
      </p>

      <br />
      <br />
      <br />
      <h3>
        <Link to="/">artnow.world</Link>
      </h3>
      <br />
    </Layout>
  );
};

export default Motz;
